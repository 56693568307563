.scale-hover {
    transition: transform 0.3s; /* Add a smooth transition */
  }
  
  .scale-hover:hover {
    transform: scale(1.05); /* Increase size on hover */
  }
//
// Card
//
.flex-stack {
    justify-content: space-between;
    align-items: center;
}
h2#swal2-title 
div:where(.swal2-container) .swal-popup-custom div:where(.swal2-icon).swal2-success .swal2-success-ring {
    border: 0.25em solid rgba(255, 255, 255, 0.812) !important; /* Set the icon color to green */
}

div:where(.swal2-container) .swal-popup-custom div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
    background-color: white !important; /* Set the title text color to white */
}
.swal-popup-custom h2#swal2-title {
    color: white !important;
}
.swal-popup-custom div#swal2-html-container {
    color: white !important;
}
.prev-button {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 49%;
  }
  
  .prev-button-fixed {
    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
    background-color: #EC520B;
    color: white;
    border: none;
    border-radius: 0px;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
  }
.next-button {
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 49%;
  }
  
  .next-button-fixed {
    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
    background-color: #EC520B;
    color: white;
    border: none;
    border-radius: 0px;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
  }
.subheader {
    // background-color: #EC520B;
    background-repeat: no-repeat;
}
.subheader-transparent {
    background-color: transparent!important;
}
.overflow-x-hidden {
    overflow-x: hidden !important;
}
// Base
@page {
    margin: 20mm 15mm 20mm 15mm;
    width: 100%;
    font-size: small;
  }
@media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      font-size: 14px;
    }
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
.position-unset {
    position: unset !important;
    bottom: unset;
}
body#kt_body {
    height: 100% !important;
}
.react-google-flight-datepicker .date-picker-input {
    height: 38px !important;
}
.react-google-flight-datepicker .date-picker {
    padding: 0 !important;
}
.end-0 {
    right: 0!important;
}
.top-50 {
    top: 50% !important;
  }
  .translate-middle-c {
    transform: translate(-10%, -50%) !important;
  }
  .translate-middle {
    transform: translate(-50%, -50%) !important;
  }
.card.card-custom {
    box-shadow: $card-box-shadow;
    border: 0;

    // Header
    > .card-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        //height: $card-header-height;
        min-height: $card-header-height;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;

        // Title
        .card-title {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y;
            margin-left: 0;
            //flex-wrap: wrap;

            .card-icon {
                margin-right: 0.75rem;
                line-height: 0;

                i {
                    font-size: 1.25rem;
                    color: $dark-50;
                    line-height: 0;

                    &:after,
                    &:before {
                        line-height: 0;
                    }
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                    @include svg-icon-color($dark-50);
                }
            }

            &,
            .card-label {
                font-weight: 500;
                font-size: 1.275rem;
                color: $dark;
            }

            .card-label {
                margin: 0 0.75rem 0 0;
                flex-wrap: wrap;
            }

            // Description
            small {
                color: $text-muted;
                font-size: 1rem;
            }
        }

        // Toolbar
        .card-toolbar {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y 0;
            flex-wrap: wrap;
        }

        // Line tabs integration
        &.card-header-tabs-line {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            align-items: stretch;

            .card-toolbar {
                margin: 0;
            }

            .nav {
                border-bottom-color: transparent;

                .nav-item {
                    align-items: stretch;
                }

                .nav-link {
                    padding-top: $card-spacer-y;
                    padding-bottom: $card-spacer-y;
                }
            }
        }

        &.card-header-right {
            justify-content: flex-end;
        }
    }

    // Body
    > .card-body {
         padding: $card-spacer-y $card-spacer-x;
    }

    // Footer
    > .card-footer {
        background-color: transparent;
    }

    // Scroll
    .card-scroll {
        position: relative;
        overflow: auto;
    }

    // Make full height
    &.card-stretch {
        display: flex;
        align-items: stretch !important;
        flex-direction: column;
        height: 100%;

        &.gutter-b {
            height: calc(100% - #{$grid-gutter-width});
        }

        &.card-stretch-half {
            height: 50%;

            &.gutter-b {
                height: calc(50% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-third {
            height: 33.33%;

            &.gutter-b {
                height: calc(33.33% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-fourth {
            height: 25%;

            &.gutter-b {
                height: calc(25% - #{$grid-gutter-width});
            }
        }
    }

    // Card header fit style
    &.card-fit {
        > .card-header {
            border-bottom: 0;
        }

        > .card-footer {
            border-top: 0;
        }
    }

    // Card space style
    &.card-space {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;

        > .card-header {
            padding-left: 0;
            padding-right: 0;
        }

        > form > .card-body,
        > .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        > form > .card-footer,
        > .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Sticky card
    &.card-sticky {
        > .card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
    		//height: $card-sticky-header-height;
    		min-height: $card-sticky-header-height;
    	}
    }

    .card-sticky-on &.card-sticky {
        > .card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
			position: fixed;
			box-shadow: $card-sticky-shadow;
			z-index: $card-sticky-zindex;
			background: $card-sticky-header-bg;
    	}
    }

    // Transparent Background
    &.card-transparent {
        background-color: transparent;
    }

    // No Shadow
    &.card-shadowless {
        box-shadow: none;
    }

    // Reset Padding X
    &.card-px-0 {
        .card-header,
        .card-body,
        .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Border style
    &.card-border {
        box-shadow: none;
        border: 1px solid $card-border-color;
    }

    // Collapsed Mode
	&.card-collapsed {
		> form,
		> .card-body {
			display: none;
		}
	}

    .card-header  {
        .card-toolbar {
            [data-card-tool="toggle"] {
                i {
                    transition: $transition;
                }
            }
        }
    }

	&.card-collapsed,
	&.card-collapse {
		.card-header  {
			.card-toolbar {
                [data-card-tool="toggle"] {
                    i {
                        transition: $transition;
                        transform: rotate(180deg);
                    }
                }
			}
		}
	}
}

// Utilities
.card-spacer {
    padding: $card-spacer-y $card-spacer-x !important;
}

.card-spacer-x {
    padding-left: $card-spacer-x !important;
    padding-right: $card-spacer-x !important;
}

.card-spacer-y {
    padding-top: $card-spacer-y !important;
    padding-bottom: $card-spacer-y !important;
}

.card-rounded {
    border-radius: $card-border-radius;
}

.card-rounded-top {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(sm) {
    .overflow-hidden {
        overflow-y: auto;
    }
    .card.card-custom {
        // Header
        > .card-header:not(.flex-nowrap) {
            min-height: 0;
            padding-top: $card-header-spacer-y;
            padding-bottom: $card-header-spacer-y;
        }
    }
}

// Card related helper classes
.rounded-card {
    @include border-radius($card-border-radius);
}

.rounded-card-top {
    @include border-top-left-radius($card-border-radius);
    @include border-top-right-radius($card-border-radius);
}

.rounded-card-bottom {
    @include border-bottom-left-radius($card-border-radius);
    @include border-bottom-right-radius($card-border-radius);
}
