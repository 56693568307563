//
// Override global variables
//

// Theme colors
$primary:       									#EC520b;
$primary-hover:    									#D44909;
$primary-light:    									#FDEde6;
$primary-inverse:  									#FFFFFF;

$info:       									    #0047ba;
$info-light:    									#e5e6f8;
$info-hover:    									#003894;
$info-inverse:  									#FFFFFF;
